.event-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
  }

  .tap-in-logo {
    width: 100px;
    height: auto;
    margin-bottom: 20px;
    animation: bounce 1s infinite;
  }
  
  @keyframes bounce {
    0%, 100% {
      transform: translateY(0);
      animation-timing-function: ease-out;
    }
    50% {
      transform: translateY(-10px);
      animation-timing-function: ease-in;
    }
  }
  
  .event-card {
    background-color: white;
    border-radius: 10px;
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
    max-width: 600px;
    width: 100%;
    padding: 20px;
    margin: 0 10px;
  }
  
  .event-content {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .event-title {
    font-size: 28px;
    font-family: 'SFRounded-Ultralight', sans-serif;
    color: #000000;
    margin-bottom: 8px;
    text-align: center;
  }
  
  .event-location {
    display: flex;
    align-items: center;
    margin-bottom: 16px;
    cursor: pointer;
  }
  
  .location-button {
    background: none;
    border: none;
    color: #000000;
    font-size: 20px;
    font-family: 'SFRounded-Ultralight', sans-serif;
    cursor: pointer;
    padding: 0;
    text-align: center;
  }
  
  .event-date {
    display: flex;
    align-items: center;
    font-size: 18px;
    font-family: 'SFRounded-Ultralight', sans-serif;
    color: #000000;
    margin: 8px 0;
  }
  
  .event-date i {
    margin-right: 8px;
  }
  
  .event-url {
    color: #000000;
    font-size: 16px;
    font-family: 'SFRounded-Ultralight', sans-serif;
    margin-top: 8px;
    text-align: center;
    display: block;
  }
  
  .event-notes {
    font-size: 16px;
    font-family: 'SFRounded-Ultralight', sans-serif;
    color: #333;
    margin-top: 16px;
    text-align: center;
    width: 50%;
    word-wrap: break-word; /* Ensure the text wraps */
  }
  
  .event-footer {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 16px;
  }
  
  .add-to-calendar-button {
    background-color: rgb(255, 255, 255);
    color: rgb(0, 0, 0);
    padding: 10px 20px;
    border-radius: 25px;
    font-size: 18px;
    font-family: 'SFRounded-Ultralight', sans-serif;
    text-decoration: none;
    text-align: center;
    margin-bottom: 10px;
    width: 100%;
    text-align: center;
    border: none;
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);

  }
  
  .add-to-calendar-button:hover {
    background-color: #d5d5d5;
  }
  
  /* Responsive design */
  @media (max-width: 600px) {
    .event-card {
      padding: 15px;
    }
  
    .event-title {
      font-size: 24px;
    }
  
    .location-button,
    .event-date,
    .event-url,
    .event-notes {
      font-size: 16px;
    }

    .event-notes {
        width: 100%;

    }
  
    .add-to-calendar-button {
      font-size: 16px;
      padding: 8px 16px;
    }
  }
  