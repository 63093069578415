.App {
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
}

.App-header {
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-logo {
  width: 200px; /* Adjust the width as needed */
  height: auto;
  animation: bounce 1s infinite;
}

.Download-logo {
  width: 275px; /* Adjust the width as needed */
  height: auto;
  margin-top: 20px; /* Add some spacing between the logos */
}

@keyframes bounce {
  0%, 100% {
    transform: translateY(0);
    animation-timing-function: ease-out;
  }
  50% {
    transform: translateY(-10px);
    animation-timing-function: ease-in;
  }
}